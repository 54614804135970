<template>
  <page>
    <div>
      <failpage>
        <h3 class="title">404</h3>
        <button class="backBtn" @click='goHome'>返回首页</button>
      </failpage>
    </div>
  </page>
</template>

<script>
import failpage from '@/components/page/fail.vue'

export default {
  components: {
    failpage: failpage
  },
  methods: {
    goHome() {
      this.$router.replace('/home')
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 32px;
  color: #333;
  text-align: center;
  margin-top: 40px;
}
.backBtn {
  height: 40px;
  width: 80%;
  border: none;
  border-radius: 80px;
  outline: none;
  background: rgb(92, 186, 240);
  color: #fff;
  font-size: 16px;
  margin: 24px auto;
  display: block;
  &:active {
    opacity: 0.8;
  }
}
</style>